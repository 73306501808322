import "./styles.css";

const bookings = {
  2022: { 1: { 3: 1 } }
};

function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

// function updatePrice() {
//   let price = 0;
//   allDays.forEach((d) => {
//     if (d.classList.contains("selected")) {
//       const day = d.date.getDay();
//       const multiplier = day === 0 ? 2 : day === 6 ? 1.5 : 1;
//       price += multiplier * 1000;
//     }
//   });
//   if (document.querySelector(".total-price")) {
//     document.querySelector(".total-price").innerHTML = price.toLocaleString();
//   }
// }

// document.querySelector(".purchase").onclick = function (ev) {
//   const days = [];
//   allDays.forEach((d) => {
//     if (d.classList.contains("selected")) {
//       days.push(d.date);
//       d.classList.add("booked");
//       d.classList.remove("selected");
//     }
//   });
//   updatePrice();
//   const a = document.createElement("a");
//   const parts = [
//     ["subject", `Project consultation`],
//     [
//       "body",
//       `Hi Ilmari,

// This is ${window.full_name.value} from ${window.company.value}.

// We want to achieve the following:

// ${window.project.value}

// This is what we need:

// ${window.description.value}

// It should be done by ${window.done_by_date.value}, we can start work on ${
//         window.start_date.value
//       }.

// This should be done for max ${window.rough_budget.value}.

// Can you help us on this? Have you done anything like this before? Any recommendations?

// Let's do a brief call if you're interested.

// ${
//   window.nda_link.value
//     ? `I can give you more details after you sign the NDA at ${window.nda_link.value}
// `
//     : ""
// }
// Thanks,
// ${window.full_name.value}

// ${
//   days.length > 0
//     ? `I have the following dates in mind: ${days
//         .map((d) =>
//           d.toDateString(void 0, {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//             weekday: "long"
//           })
//         )
//         .join(", ")}`
//     : ""
// }
// `
//     ]
//   ];
//   console.log(parts);
//   a.href =
//     "mailto:hei@heichen.hk?" +
//     parts.map((a) => a.map(encodeURIComponent).join("=")).join("&");
//   a.target = "_blank";
//   a.click();
// };
/*
const appEl = document.getElementById("app");
var lastSelection = null;
const allDays = [];

for (let year = 2022; year < 2023; year++) {
  const yearEl = document.createElement("div");
  yearEl.className = "year";
  yearEl.innerHTML = `<h2>${year}</h2>`;
  const monthsEl = document.createElement("div");
  monthsEl.className = "months";
  yearEl.append(monthsEl);
  appEl.append(yearEl);
  for (let month = 0; month < 12; month++) {
    const days = getDaysInMonth(month, year);
    const monthEl = document.createElement("div");
    monthEl.className = "month";
    monthEl.innerHTML = `<h3>${days[0].toLocaleString("default", {
      month: "long"
    })}</div>`;
    const daysEl = document.createElement("div");
    daysEl.className = "days";
    monthEl.append(daysEl);
    monthsEl.append(monthEl);
    ["S", "M", "T", "W", "T", "F", "S"].forEach((d) => {
      const el = document.createElement("div");
      el.innerHTML = d;
      daysEl.append(el);
    });
    for (let i = 0; i < days[0].getDay(); i++) {
      daysEl.append(document.createElement("div"));
    }
    days.forEach((d) => {
      const dayEl = document.createElement("div");
      allDays.push(dayEl);
      dayEl.className = "day";
      dayEl.innerHTML = d.getDate();
      dayEl.date = d;
      if (
        bookings[year] &&
        bookings[year][month + 1] &&
        bookings[year][month + 1][d.getDate()]
      ) {
        dayEl.classList.add("booked");
      }
      if (d.getDay() === 0) {
        dayEl.classList.add("holiday");
      }
      if (d.getDay() === 6) {
        dayEl.classList.add("semi-holiday");
      }
      dayEl.onclick = function (ev) {
        if (ev && ev.shiftKey && lastSelection) {
          let start = allDays.indexOf(lastSelection);
          let end = allDays.indexOf(this);
          if (start > end) [start, end] = [end, start];
          const sel = lastSelection.classList.contains("selected");
          for (let i = start; i <= end; i++) {
            const day = allDays[i];
            if (
              !day.classList.contains("booked") &&
              day.date.getDay() !== 0 &&
              day.date.getDay() !== 6
            ) {
              day.classList.toggle("selected", sel);
            }
          }
          lastSelection = this;
          updatePrice();
          return;
        }
        if (!this.classList.contains("booked")) {
          this.classList.toggle("selected");
          lastSelection = this;
          updatePrice();
        }
      };
      daysEl.append(dayEl);
    });
  }
}
*/
